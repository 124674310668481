import { defineStore } from 'pinia'
import { apiGet, apiPost } from '~/services/api'
import { PopUpStore } from '~/store/popUp'
import { getCoordsByString } from '~/helpers/Map/getCoordsByString'
import { reachGoal } from '~/services/YandexMetrika/reachGoal'
import { isMobile } from 'vue-device-detect'

export const useFormStore = defineStore('form', {
  state: () => ({
    cookieAgrement: false,
    first_name: '',
    last_name: '',
    middle_name: '',
    phone: '',
    city: '',
    cityCoords: [] as number[],
    contacts: { tg: '', wa: '', inst: '', vib: '', email: '', extraPhone: '' },
    isTGbotSet: false,
    notifications: [
      {
        name: 'Клиент посмотрел презентацию',
        id: 1,
        value: false,
      },
      {
        name: 'Клиент записался на просмотр объекта',
        id: 1,
        value: false,
      },
      {
        name: 'Найден подходящий объект',
        id: 1,
        value: false,
      },
      {
        name: 'Найден покупатель на ваш объект',
        id: 1,
        value: false,
      },
      {
        name: 'Напоминания о забытых клиентах',
        id: 1,
        value: false,
      },
      {
        name: 'Уведомление об оплате',
        id: 1,
        value: false,
      },
    ],
    isVizitka: false,
    isPrivacy: false,
    employment_status: [] as string[],
    directions: [] as string[],
    about_myself: '',
    userAvatarLink: undefined,
    loaderProfilePage: true,
    loaderAwadrsPage: true,
    ImgloaderVisible: true,
    userSurveyBackTrigger: [],
    userSurveyFrontTrigger: null,
    loginInputError: false,
    winH: 0,
    winW: 0,
    isMobile: isMobile,
    isKeyboardOpen: false,
  }),
  actions: {
    setScreenSizeInCSS() {
      this.winW = window.innerWidth
      this.winH = window.innerHeight
      document.documentElement.style.setProperty('--screen-width', `${this.winW}px`)
      document.documentElement.style.setProperty('--screen-height', `${this.winH}px`)
      this.removeChatBot()
      const handleResize = () => {
        this.winH = window.innerHeight
        if (this.winH < 530) {
          this.isKeyboardOpen = true
        } else {
          this.isKeyboardOpen = false
        }
      }
      window.addEventListener('resize', handleResize)
    },
    async fetchUserData() {
      try {
        const { data } = await apiGet({ url: 'api/users/profile' })
        if (!data.value) return
        this.setUserData(data.value.data)
      } catch (e) {
        console.log(e)
      } finally {
        this.loaderProfilePage = false
      }
    },
    async setUserData(data: any) {
      if (!data) return
      this.cityCoords = await getCoordsByString(data.city)
      this.first_name = data.first_name
      this.last_name = data.last_name
      this.middle_name = data.middle_name
      this.phone = data.phone
      this.city = data.city
      this.employment_status = [data.employment_status]
      this.directions = data.directions
      this.about_myself = data.about_myself
      this.contacts.email = data.email
      this.contacts.tg = data.telegram
      this.contacts.wa = data.whatsapp
      this.contacts.vib = data.viber
      this.contacts.inst = data.instagram
      this.contacts.extraPhone = data.additional_phone
    },
    resetUserData() {
      this.cityCoords = []
      this.first_name = ''
      this.last_name = ''
      this.middle_name = ''
      this.phone = ''
      this.city = ''
      this.employment_status = []
      this.directions = []
      this.about_myself = ''
      this.contacts.email = ''
      this.contacts.tg = ''
      this.contacts.wa = ''
      this.contacts.vib = ''
      this.contacts.inst = ''
      this.contacts.extraPhone = ''
      this.userAvatarLink = undefined
    },
    async postUserData() {
      const form = {
        first_name: this.first_name,
        last_name: this.last_name,
        middle_name: this.middle_name,
        phone: this.phone,
        city: this.city,
        employment_status: this.employment_status[0],
        about_myself: this.about_myself,
        directions: this.directions,
        email: this.contacts.email,
        telegram: this.contacts.tg,
        whatsapp: this.contacts.wa,
        viber: this.contacts.vib,
        instagram: this.contacts.inst,
        additional_phone: this.contacts.extraPhone,
      }
      try {
        const { data } = await apiPost({ url: 'api/users/profile', body: { ...form } })
        if (data) {
          ElNotification({
            type: 'success',
            title: 'Успешно',
            message: 'Данные пользователя изменены',
          })
        }
        const userMetrikaAcc = {
          city: this.city,
          employment_status: this.employment_status[0],
          directions: this.directions,
        }
        const userMetrikaContact = {
          email: this.contacts.email,
          telegram: this.contacts.tg,
          whatsapp: this.contacts.wa,
          viber: this.contacts.vib,
          instagram: this.contacts.inst,
          additional_phone: this.contacts.extraPhone,
        }
        reachGoal('Аккаунт', userMetrikaAcc)
        reachGoal('Контакты', this.replaceAndRemoveEmptyProperties(userMetrikaContact))
      } catch (e) {
        console.log(e)
      }
    },

    async fetchUserAvatar() {
      try {
        const { data } = await apiGet({ url: 'api/users/profile/avatar' })
        if (data) {
          this.userAvatarLink = (data as any).value.url
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.ImgloaderVisible = false
      }
    },
    async fetchSurvey() {
      try {
        const { data } = await apiGet({ url: 'api/question/questions-for-user' })
        if (data) {
          this.userSurveyBackTrigger = (data as any).value.backendTriggerQuestions
          this.userSurveyFrontTrigger = (data as any).value.userSurveyFrontTrigger
        }
      } catch (e) {
        console.log(e)
      } finally {
        if (this.userSurveyBackTrigger?.length != 0) {
          const PopUpControl = PopUpStore()
          PopUpControl.callPopUp({ value: 'AfterRegistration' })
        }
        this.ImgloaderVisible = false
      }
    },
    replaceAndRemoveEmptyProperties(obj: { [key: string]: any }) {
      const filledObj = { ...obj }

      for (const key in filledObj) {
        if (filledObj.hasOwnProperty(key)) {
          if (filledObj[key]) {
            filledObj[key] = 'Заполнено'
          } else {
            delete filledObj[key]
          }
        }
      }

      return filledObj
    },
    removeChatBot() {
      if (this.isMobile && window.Tawk_API && window.Tawk_API.hideWidget) {
        window.Tawk_API.hideWidget()
      } else if (this.isMobile) {
        var scripts = document.getElementsByTagName('script')
        for (var i = scripts.length - 1; i >= 0; i--) {
          var script = scripts[i]
          if (script.innerHTML.includes('Tawk_API') || script.src.includes('Tawk_API')) {
            script.parentNode?.removeChild(script)
          }
        }
      }
    },
  },
})
